<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { Link, router } from '@inertiajs/vue3';

//Components

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import EventCard from '@/Pages/Dashboard/Pages/Events/Components/EventCard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

const props = defineProps({
    events: Object,
    ministers: Object,
    leaders: Object,
    schedules: Object,
    eventStatus: Object,
    eventTypes: Object,
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const searchForm = reactive({
    name: null,
    status: 0,
    type: 0,
    minister_id: 0,
    leader_id: 0,
    schedule_id: 0,
    start_date: '',
    end_date: '',
});

const seacher = () => router.get('/dashboard/events', searchForm, { preserveState: true });

const initializeSearchForm = () => {
    const urlParams = new URLSearchParams(window.location.search);

    const fields = [
        'name',
        'type',
        'status',
        'minister_id',
        'leader_id',
        'schedule_id',
        'start_date',
        'end_date',
    ];
    fields.forEach((field) => {
        if (urlParams.has(field)) {
            searchForm[field] = urlParams.get(field);
        }
    });
};

onMounted(() => {
    initializeSearchForm();
});
</script>

<template>
    <Dashboard page-title="Eventos">
        <Breadcrumb current-page-name="Eventos" />
        <div class="row mt-3 mb-4">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Eventos</h4>
                <Link href="/dashboard/events/create" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Novo
                </Link>
            </div>
        </div>

        <div class="p-3 search-form-card">
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-4">
                        <label class="form-label" for="floatingInput">Nome do evento</label>
                        <input
                            id="floatingInput"
                            type="email"
                            class="form-control"
                            :value="` `"
                            placeholder="Culto de celebração"
                            @input="compositionSeacher('name', $event)"
                        />
                    </div>
                    <div class="col-md-4">
                        <label for="floatingSelect" class="bg-transparent form-label"
                            >Selecionar dirigente</label
                        >
                        <select
                            id="leader_id"
                            v-model="searchForm.leader_id"
                            class="form-select"
                            required
                            @change="seacher()"
                        >
                            <option value="0">Todos os dirigentes</option>
                            <option v-for="(item, index) in leaders" :key="index" :value="item.id">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-4">
                        <label for="floatingSelect" class="bg-transparent form-label"
                            >Selecionar ministro</label
                        >
                        <select
                            id="minister_id"
                            v-model="searchForm.minister_id"
                            class="form-select"
                            required
                            @change="seacher()"
                        >
                            <option value="0">Todos os ministros</option>
                            <option
                                v-for="(item, index) in ministers"
                                :key="index"
                                :value="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-4">
                        <label class="form-label" for="floatingInput">Status</label>
                        <select
                            id="status"
                            v-model="searchForm.status"
                            class="form-select"
                            required
                            @change="seacher()"
                        >
                            <option value="0">Todas os status</option>
                            <option
                                v-for="(item, index) in eventStatus"
                                :key="index"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-6 col-sm-4">
                        <label for="startDate" class="form-label">Data de início</label>
                        <input
                            id="startDate"
                            v-model="searchForm.start_date"
                            type="date"
                            class="form-control"
                            name=""
                            @change="seacher()"
                        />
                    </div>
                    <div class="col-6 col-sm-4">
                        <label for="endDate" class="form-label">Data de fim</label>
                        <input
                            id="endDate"
                            v-model="searchForm.end_date"
                            type="date"
                            class="form-control"
                            name=""
                            @change="seacher()"
                        />
                    </div>

                    <div class="col-md-4">
                        <label for="floatingSelect" class="bg-transparent form-label"
                            >Selecionar programação</label
                        >
                        <select
                            id="schedule_id"
                            v-model="searchForm.schedule_id"
                            class="form-select"
                            required
                            @change="seacher()"
                        >
                            <option value="0">Todas as programações</option>
                            <option
                                v-for="(item, index) in schedules"
                                :key="index"
                                :value="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-4">
                        <label class="form-label" for="floatingInput">Tipo</label>
                        <select
                            id="type"
                            v-model="searchForm.type"
                            class="form-select"
                            required
                            @change="seacher()"
                        >
                            <option value="0">Todos os tipos</option>
                            <option
                                v-for="(item, index) in eventTypes"
                                :key="index"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-3 px-3">
                <hr />
                <EventCard v-for="(item, index) in events.data" :key="index" :event="item" />
                <Pagination :data="events.meta" reference-date="events" identification="Eventos" />
            </div>
        </div>
    </Dashboard>
</template>
