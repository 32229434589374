<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({ name: String });

const statusStyle = computed(() => {
    const style = {
        NEW_BIRTH: {
            color: 'white',
            background: 'primary',
            name: 'Novo nascimento',
        },
        RECONCCILIATION: {
            color: 'dark',
            background: 'warning',
            name: 'Reconciliação',
        },
        MARRIED: {
            color: 'white',
            background: 'danger',
            name: 'Casado (a)',
        },
        SINGLE: {
            color: 'white',
            background: 'secondary',
            name: 'Solteiro (a)',
        },
        DIVORCED: {
            color: 'white',
            background: 'dark',
            name: 'Divorciado (a)',
        },
        OTHER: {
            color: 'dark',
            background: 'warning',
            name: 'Outra situação',
        },
        FRIEND: {
            color: 'white',
            background: 'primary',
            name: 'Amigo',
        },
        BROTHER: {
            color: 'dark',
            background: 'warning',
            name: 'Irmão',
        },
        SOCIAL_MEDIA: {
            color: 'dark',
            background: 'warning',
            name: 'Social media',
        },
        OTHERS: {
            color: 'dark',
            background: 'warning',
            name: 'Outros',
        },
        MALE: {
            color: 'white',
            background: 'dark',
            name: 'Masculino',
        },
        FEMALE: {
            color: 'white',
            background: 'secondary',
            name: 'Feminino',
        },
        CULT: {
            color: 'white',
            background: 'primary',
            name: 'Culto',
        },
        VISITOR: {
            color: 'dark',
            background: 'warning',
            name: 'Visitante',
        },
        MEMBER: {
            color: 'white',
            background: 'success',
            name: 'Membro',
        },
        INACTIVE: {
            color: 'white',
            background: 'danger',
            name: 'Inativo',
        },
        YES: {
            color: 'white',
            background: 'success',
            name: 'Sim',
        },
        NOT: {
            color: 'white',
            background: 'danger',
            name: 'Não',
        },
        INCOMPLETE_PRIMARY: {
            color: 'white',
            background: 'danger',
            name: 'Primário Incompleto',
        },
        COMPLETE_PRIMARY: {
            color: 'white',
            background: 'success',
            name: 'Primário Completo',
        },
        INCOMPLETE_HIGH_SCHOOL: {
            color: 'white',
            background: 'danger',
            name: 'Ensino Médio Incompleto',
        },
        COMPLETE_HIGH_SCHOOL: {
            color: 'white',
            background: 'danger',
            name: 'Ensino Médio Completo',
        },
        INCOMPLETE_COLLEGE: {
            color: 'white',
            background: 'danger',
            name: 'Faculdade Incompleta',
        },
        COMPLETE_COLLEGE: {
            color: 'white',
            background: 'success',
            name: 'Faculdade Completa',
        },
        INCOMPLETE_POSTGRADUATE_DEGREE: {
            color: 'white',
            background: 'danger',
            name: 'Pós-Graduação Incompleta',
        },
        COMPLETE_POSTGRADUATE_DEGREE: {
            color: 'white',
            background: 'success',
            name: 'Pós-Graduação Completa',
        },
        INCOMPLETE_MBA: {
            color: 'white',
            background: 'danger',
            name: 'MBA Incompleto',
        },
        COMPLETE_MBA: {
            color: 'white',
            background: 'success',
            name: 'MBA Completo',
        },
        INCOMPLETE_PHD: {
            color: 'white',
            background: 'danger',
            name: 'PHD Incompleto',
        },
        COMPLETE_PHD: {
            color: 'white',
            background: 'success',
            name: 'PHD Completo',
        },
        INCOMPLETE_POST_PHD: {
            color: 'white',
            background: 'danger',
            name: 'Pós-Doutoramento Incompleto',
        },
        COMPLETE_POST_PHD: {
            color: 'white',
            background: 'success',
            name: 'Pós-Doutoramento Completo',
        },
        ACTIVATE: {
            color: 'white',
            background: 'success',
            name: 'Ativo',
        },
        DEACTIVATE: {
            color: 'white',
            background: 'danger',
            name: 'Desativado',
        },
        CLOSED: {
            color: 'white',
            background: 'danger',
            name: 'Encerrado',
        },
        EVANGELIZATION: {
            color: 'white',
            background: 'danger',
            name: 'Evangelização',
        },
        NEW_CONVERT: {
            color: 'white',
            background: 'primary',
            name: 'Novo Convertido',
        },
        PAID_EVENT: {
            color: 'white',
            background: 'primary',
            name: 'Evento Pago',
        },
        CASH: {
            color: 'white',
            background: 'success',
            name: 'Dinheiro',
        },
        CARD: {
            color: 'white',
            background: 'primary',
            name: 'Cartão',
        },
        PIX: {
            color: 'dark',
            background: 'warning',
            name: 'PIX',
        },
    };
    return (
        style[props.name] || {
            color: 'dark',
            background: 'warning',
            name: props.name,
        }
    );
});
</script>

<template>
    <span :class="`badge bg-${statusStyle.background} text-${statusStyle.color} p-2`">
        {{ statusStyle.name }}
    </span>
</template>
