<script setup>
import { computed } from 'vue';
import { Link } from '@inertiajs/vue3';

import useDateFormat from '@/Pages/Dashboard/Composables/DateFormat.vue';

const { simplifyDate } = useDateFormat();

const props = defineProps({
    data: Object,
    responsible: String,
});

const eventDetails = computed(() => {
    if (props.responsible === 'minister') {
        return {
            nameEvent: props.data.theme,
            detailsEvent: props.data.details,
        };
    } else {
        return {
            nameEvent: props.data.event.name,
            detailsEvent: props.data.event.details,
        };
    }
});
</script>

<template>
    <div class="card-event-simple my-3 shadow-sm p-4">
        <div class="row">
            <div class="col-sm-5 d-flex justify-content-center">
                <div class="card-banner-event position-relative">
                    <img
                        class="shadow-sm event-banner"
                        :src="`/storage/${data.event.banner}`"
                        alt=""
                    />
                    <div class="position-absolute top-0 text-white start-time p-2">
                        {{ simplifyDate(data.event.start_time) }}
                    </div>
                </div>
            </div>

            <div class="col-sm-7">
                <h5 class="mt-3 mt-sm-0">{{ eventDetails.nameEvent }}</h5>
                <p
                    v-if="eventDetails.detailsEvent"
                    class="minister-event-details bg-info bg-opacity-10 mt-3 border-start border-5 border-warning p-3"
                >
                    {{ eventDetails.detailsEvent }}
                </p>

                <div class="d-flex justify-content-between align-items-center event-options">
                    <span>
                        <font-awesome-icon class="me-2" :icon="['fas', 'users']" />
                        {{
                            data.event.censu?.total_people
                                ? `${data.event.censu.total_people}
                        presentes`
                                : 'Não há censo'
                        }}
                    </span>

                    <Link
                        :href="`/dashboard/events/${data.event.id}`"
                        class="btn btn-primary btn-add"
                    >
                        Acessar evento
                    </Link>
                </div>
            </div>
        </div>
    </div>
</template>
