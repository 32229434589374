<script setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';

//Components

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Shared/PhotoEditModal.vue';

const form = useForm({
    name: null,
    identification_photo: '/img/avatars/male.png',
    personal_summary: null,
});

const inputRef = ref(null);

const store = () => form.post('/dashboard/leaders');

const handleImageResized = (blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        form.identification_photo = e.target.result;
    };
    reader.readAsDataURL(blob);
};
</script>

<template>
    <Dashboard page-title="Novo dirigente">
        <Breadcrumb
            current-page-name="Novo dirigente"
            :link-list="[
                {
                    previous_page_url: 'leaders',
                    previous_page_name: 'Dirigentes',
                },
            ]"
        />
        <h4 class="my-3">Novo dirigente</h4>

        <Form
            button-name="Cadastrar"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="d-flex justify-content-center">
                <div class="position-relative">
                    <img
                        :src="form.identification_photo"
                        alt="Identification Photo"
                        class="upload-box img-fluid shadow-sm"
                    />
                    <div class="position-absolute bottom-0 end-0 m-3">
                        <a
                            href="#"
                            data-bs-toggle="modal"
                            data-bs-target="#photoUpdateModal"
                            class="btn btn-edit shadow"
                        >
                            <font-awesome-icon
                                class="text-white"
                                :icon="['fas', 'pen-to-square']"
                            />
                        </a>
                    </div>
                </div>
            </div>

            <div
                class="alert alert-danger mx-2"
                role="alert"
                v-if="form.errors.identification_photo"
            >
                {{ form.errors.identification_photo }}
            </div>

            <div class="col-md-12">
                <label for="name" class="form-label"> Nome do dirigente </label>

                <input
                    id="name"
                    v-model="form.name"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Darren Wray"
                    required
                />
                <small v-if="form.errors.name" class="text-danger mt-3">
                    {{ form.errors.name }}
                </small>
            </div>

            <div class="col-md-12">
                <label for="personal_summary" class="form-label"> Observações </label>
                <small class="optional ms-2">(opcional)</small>

                <textarea
                    id="personal_summary"
                    v-model="form.personal_summary"
                    type="text"
                    class="form-control"
                    name="personal_summary"
                    placeholder="Forneça alguma observação"
                    rows="5"
                />

                <small v-if="form.errors.personal_summary" class="text-danger mt-3">
                    {{ form.errors.personal_summary }}
                </small>
            </div>

            <input type="file" ref="inputRef" class="file-input d-none" />
        </Form>
        <PhotoEditModal
            v-if="form.identification_photo"
            title="Adicionar foto"
            id="photoUpdateModal"
            @image-resized="handleImageResized"
        />
    </Dashboard>
</template>
