<script setup>
import { reactive, defineProps, onMounted, ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import { vMaska } from 'maska/vue';
import { VMoney } from 'v-money';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

import useMoney from '@/Pages/Dashboard/Composables/Money.vue';
import usePriceFormatter from '@/Pages/Dashboard/Composables/PriceFormatter.vue';

const props = defineProps({
    event: Object,
    members: Object,
    responsibles: Object,
    paymentMethods: Object,
    marriedMembers: Object,
});

const mounted = ref(false);

const { money } = useMoney();
const { convertDecimalFormat } = usePriceFormatter();

const form = useForm({
    name: null,
    member_id: null,
    responsible_id: null,
    responsible_name: null,
    payment_method: props.paymentMethods[0].value,
    amount_paid: props.event.registration_fee,
    responsible: false,
    married: false,
    married_name: null,
    married_id: null,
});

const seacher = reactive({
    member_name: null,
    responsible_name: null,
    married_name: null,
});

const member = ref(false);

const search = () => {
    router.visit(`/dashboard/events/${props.event.id}/members/create`, {
        method: 'get',
        data: { seacher },
        only: ['members', 'responsibles', 'marriedMembers'],
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            form.member_id = props.members ? form.member_id : null;
            form.responsible_id = props.responsibles ? form.responsible_id : null;

            member.value = false;
        },
    });
};

let timeoutId = null;

const compositionSeacher = (field, event) => {
    seacher[field] = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
        search();
    }, 1000);
};

const store = () => {
    form.transform((data) => ({
        ...data,
        amount_paid: convertDecimalFormat(data.amount_paid),
    })).post(`/dashboard/events/${props.event.id}/members`);
};

const toggleSelection = (field, id) => {
    if (field === 'member_id') {
        member.value = props.members.find((v) => v.id == id);
    }
    if (form[field] === id) {
        form[field] = null;
        member.value = false;
    } else {
        form[field] = id;
    }
};

const clearQueryParams = () => router.replace(`/dashboard/events/${props.event.id}/members/create`);

onMounted(() => {
    clearQueryParams();
    setInterval(() => (mounted.value = true), 2000);
});
</script>

<template>
    <Dashboard page-title="Novo">
        <Breadcrumb
            current-page-name="Novo"
            :link-list="[
                {
                    previous_page_url: `events/${event.id}`,
                    previous_page_name: 'Evento',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-sm-6">
                <h4 class="font-bold m-0">Novo inscrição</h4>
            </div>
            <div class="col-sm-6 mt-3 mt-sm-0 text-end pe-0">
                <Link :href="`/dashboard/events/${event.id}`" class="btn btn-success btn-add me-2">
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar evento
                </Link>
            </div>
        </div>

        <Form
            button-name="Cadastrar"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="row g-3">
                <div class="col-md-12">
                    <label for="name" class="form-label">Nome</label>
                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="João Pedro de Souza"
                        required
                        @input="compositionSeacher('member_name', $event)"
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="col-md-6">
                    <label for="payment_method" class="form-label">Modo de pagamento</label>
                    <select
                        id="payment_method"
                        v-model="form.payment_method"
                        class="form-select form-select"
                        aria-label="Small select example"
                    >
                        <option
                            v-for="(item, index) in paymentMethods"
                            :key="index"
                            :value="item.value"
                        >
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-6">
                    <label for="amount_paid" class="form-label">Valor pago</label>
                    <input
                        id="amount_paid"
                        v-model.lazy="form.amount_paid"
                        v-money="money"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder=""
                        required
                    />
                    <small v-if="form.errors.amount_paid" class="text-danger mt-3">
                        {{ form.errors.amount_paid }}
                    </small>
                </div>

                <div class="col-md-12">
                    <div class="form-check d-flex align-items-center form-switch">
                        <input
                            id="flexCheckDefault"
                            v-model="form.responsible"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked
                        />
                        <label class="form-check-label ms-3" for="flexCheckDefault">
                            Possui responsável?
                        </label>
                    </div>
                </div>

                <div class="col-md-12">
                    <div class="form-check d-flex align-items-center form-switch">
                        <input
                            id="flexCheckDefault"
                            v-model="form.married"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked
                        />
                        <label class="form-check-label ms-3" for="flexCheckDefault">
                            Casadinha?
                        </label>
                    </div>
                </div>
            </div>

            <div class="col-md-12" v-if="form.married">
                <label for="married_name" class="form-label"
                    >Nome da pessoa da casadinha relacionada</label
                >
                <input
                    id="married_name"
                    v-model="form.married_name"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Jadson Gomes Santos"
                    @input="compositionSeacher('married_name', $event)"
                />
                <small v-if="form.errors.married_name" class="text-danger mt-3">
                    {{ form.errors.married_name }}
                </small>
            </div>

            <template v-if="mounted && marriedMembers?.length">
                <hr />

                <legend class="m-0">Inscrito da casadinha relacionada</legend>

                <ul class="list-group member-found">
                    <li
                        v-for="(item, index) in marriedMembers"
                        :key="index"
                        class="list-group-item p-2 d-flex align-items-center mb-3"
                        :class="{ checked: form.married_id === item.id }"
                    >
                        <input
                            :id="'checkBoxVisitor' + item.id"
                            class="form-check-input me-1 mb-0"
                            type="checkbox"
                            name="listGroupRadio"
                            :value="item.id"
                            :checked="form.married_id === item.id"
                            @change="toggleSelection('married_id', item.id)"
                        />
                        <label
                            class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                            :for="'checkBoxVisitor' + item.id"
                            style="cursor: pointer"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ item.name }}</span>
                            </div>
                        </label>
                    </li>
                </ul>
                <hr class="my-0" />
            </template>

            <template v-if="mounted && members?.length">
                <hr />

                <legend class="m-0">Membro relacionado</legend>

                <ul class="list-group member-found">
                    <li
                        v-for="(item, index) in members"
                        :key="index"
                        class="list-group-item p-2 d-flex align-items-center mb-3"
                        :class="{ checked: form.member_id === item.id }"
                    >
                        <input
                            :id="'checkBoxVisitor' + item.id"
                            class="form-check-input me-1 mb-0"
                            type="checkbox"
                            name="listGroupRadio"
                            :value="item.id"
                            :checked="form.member_id === item.id"
                            @change="toggleSelection('member_id', item.id)"
                        />
                        <label
                            class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                            :for="'checkBoxVisitor' + item.id"
                            style="cursor: pointer"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ item.name }}</span>
                                <a
                                    :href="`/dashboard/members/${item.id}`"
                                    class="btn btn-primary btn-add z-2 me-3"
                                    target="_blank"
                                    >Acessar perfil</a
                                >
                            </div>
                        </label>
                    </li>
                </ul>
                <hr class="my-0" />
            </template>

            <template v-if="mounted && form.responsible">
                <div class="col-md-12">
                    <label for="responsible_name" class="form-label">Nome do responsável</label>
                    <input
                        id="responsible_name"
                        v-model="form.responsible_name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="João Pedro de Souza"
                        required
                        @input="compositionSeacher('responsible_name', $event)"
                    />
                    <small v-if="form.errors.responsible_name" class="text-danger mt-3">
                        {{ form.errors.responsible_name }}
                    </small>
                </div>

                <template v-if="responsibles?.length">
                    <hr />

                    <legend class="m-0">Responsável relacionado</legend>

                    <ul class="list-group member-found">
                        <li
                            v-for="(item, index) in responsibles"
                            :key="index"
                            class="list-group-item p-2 d-flex align-items-center mb-3"
                            :class="{ checked: form.responsible_id === item.id }"
                        >
                            <input
                                :id="'checkBoxVisitor' + item.id"
                                class="form-check-input me-1 mb-0"
                                type="checkbox"
                                name="listGroupRadio"
                                :value="item.id"
                                :checked="form.responsible_id === item.id"
                                @change="toggleSelection('responsible_id', item.id)"
                            />
                            <label
                                class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                                :for="'checkBoxVisitor' + item.id"
                                style="cursor: pointer"
                            >
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>{{ item.name }}</span>
                                    <a
                                        :href="`/dashboard/members/${item.id}`"
                                        class="btn btn-primary btn-add z-2 me-3"
                                        target="_blank"
                                        >Acessar perfil</a
                                    >
                                </div>
                            </label>
                        </li>
                    </ul>
                </template>
            </template>
        </Form>
    </Dashboard>
</template>
