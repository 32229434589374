<script setup>
import { defineProps } from 'vue';
import { Link } from '@inertiajs/vue3';

const props = defineProps({
    data: Object,
    referenceDate: String,
    identification: String,
});

const formatLabel = (label) => {
    const labels = {
        '&laquo; Previous': `Voltar`,
        'Next &raquo;': `Próximo`,
    };
    return labels[label] || label;
};

const formatNumber = (number) => {
    return number < 10 ? '0' + number : number;
};
</script>

<template>
    <div class="row d-flex align-items-center justify-content-between full-pagination m-2">
        <span v-if="data.total" class="details col-12 col-sm-4">
            {{ formatNumber(data.total) }} {{ identification }} encontrados(a)
        </span>

        <span v-else class="details col-12 col-sm-4">
            Nenhum {{ identification.slice(0, -1) }} encontrado(a)
        </span>

        <nav
            v-if="data.links.length > 3"
            class="d-flex justify-content-sm-end d-block mt-3 mt-sm-0 col-12 col-sm-8"
        >
            <ul class="pagination mb-0">
                <li v-for="(link, index) in data.links" :key="index" class="page-item">
                    <Link
                        class="page-link font-semibold mx-1"
                        :class="{ active: link.active, disabled: !link.url }"
                        :only="[`${referenceDate}`]"
                        preserve-state
                        preserve-scroll
                        :href="link.url || '#'"
                        v-html="formatLabel(formatNumber(link.label))"
                    />
                </li>
            </ul>
        </nav>
    </div>
</template>
