<script setup>
import { useForm } from '@inertiajs/vue3';

import { vMaska } from 'maska/vue';
import { VMoney } from 'v-money';

//Components

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Shared/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Shared/Form.vue';

// Composables

import usePreviewImage from '@/Pages/Dashboard/Composables/PreviewImage.vue';
import useMoney from '@/Pages/Dashboard/Composables/Money.vue';
import usePriceFormatter from '@/Pages/Dashboard/Composables/PriceFormatter.vue';

const props = defineProps({
    eventTypes: Object,
    eventStatus: Object,
    schedules: Object,
    ministers: Object,
    leaders: Object,
    requester: String,
});

const { previewImage, imageUrl } = usePreviewImage();
const { money } = useMoney();
const { convertDecimalFormat } = usePriceFormatter();

const form = useForm({
    name: null,
    details: null,
    start_time: null,
    banner: null,
    type: props.eventTypes[0].value,
    status: props.eventStatus[0].value,
    schedule_id: props.schedules[0].id,
    youtube_url: null,
    details_ministry: null,
    theme: null,
    ministers: [],
    leaders: [],
    requester: props.requester,
    registration_fee: '',
});

const store = () => {
    form.transform((data) => ({
        ...data,
        registration_fee: convertDecimalFormat(data.registration_fee),
    })).post('/dashboard/events');
};

const toggleMinisterSelection = (ministerId) => {
    const index = form.ministers.indexOf(ministerId);
    if (index === -1) {
        form.ministers.push(ministerId);
    } else {
        form.ministers.splice(index, 1);
    }
};

const toggleLeaderSelection = (leaderId) => {
    const index = form.leaders.indexOf(leaderId);
    if (index === -1) {
        form.leaders.push(leaderId);
    } else {
        form.leaders.splice(index, 1);
    }
};
</script>

<template>
    <Dashboard page-title="Novo evento">
        <Breadcrumb
            current-page-name="Novo evento"
            :link-list="[{ previous_page_url: 'events', previous_page_name: 'Eventos' }]"
        />
        <h4 class="my-3">Novo evento</h4>

        <Form
            button-name="Cadastrar"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-md-5">
                <label for="name" class="form-label"> Nome do evento </label>

                <input
                    id="name"
                    v-model="form.name"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Culto de consagração"
                    required
                />
                <small v-if="form.errors.name" class="text-danger mt-3">
                    {{ form.errors.name }}
                </small>
            </div>

            <div class="col-md-4">
                <label for="start_time" class="form-label"> Horário de início </label>
                <small class="optional ms-2">(opcional)</small>

                <input
                    id="start_time"
                    v-model="form.start_time"
                    type="datetime-local"
                    autocomplete="off"
                    class="form-control"
                    placeholder=""
                    required
                />
                <small v-if="form.errors.start_time" class="text-danger mt-3">
                    {{ form.errors.start_time }}
                </small>
            </div>

            <div class="col-md-3">
                <label for="type" class="form-label">Tipo do evento</label>
                <select
                    id="type"
                    v-model="form.type"
                    class="form-select form-select"
                    aria-label="Small select example"
                >
                    <option v-for="(item, index) in eventTypes" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-5">
                <label for="youtube_url" class="form-label"> Link Transmissão do Youtube </label>
                <small class="optional ms-2">(opcional)</small>

                <input
                    id="youtube_url"
                    v-model="form.youtube_url"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="https://www.youtube.com/watch?verbodavida"
                />
                <small v-if="form.errors.youtube_url" class="text-danger mt-3">
                    {{ form.errors.youtube_url }}
                </small>
            </div>

            <div class="col-md-4">
                <label for="schedule_id" class="form-label">Programação relacionada</label>
                <select
                    id="schedule_id"
                    v-model="form.schedule_id"
                    class="form-select form-select"
                    aria-label="Small select example"
                >
                    <option v-for="(item, index) in schedules" :key="index" :value="item.id">
                        {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-3">
                <label for="status" class="form-label">Status</label>
                <select
                    id="status"
                    v-model="form.status"
                    class="form-select form-select"
                    aria-label="Small select example"
                >
                    <option v-for="(item, index) in eventStatus" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-12">
                <label for="details" class="form-label"> Descrição </label>
                <small class="optional ms-2">(opcional)</small>

                <textarea
                    id="details"
                    v-model="form.details"
                    type="text"
                    class="form-control"
                    name="details"
                    placeholder="Forneça um breve resumo dessa programação"
                    rows="5"
                />

                <small v-if="form.errors.details" class="text-danger mt-3">
                    {{ form.errors.details }}
                </small>
            </div>

            <div class="col-md-12" v-if="form.type == 'PAID_EVENT'">
                <div class="row">
                    <label for="inputPassword" class="col-sm-9 col-form-label"
                        >Valor da inscrição</label
                    >
                    <div class="col-sm-3">
                        <input
                            id="registration_fee"
                            v-model="form.registration_fee"
                            type="text"
                            autocomplete="off"
                            class="form-control"
                            v-model.lazy="form.registration_fee"
                            v-money="money"
                        />
                        <small v-if="form.errors.registration_fee" class="text-danger mt-3">
                            {{ form.errors.registration_fee }}
                        </small>
                    </div>
                </div>
            </div>

            <hr class="mb-0" />

            <legend class="mb-4">Selecione o dirigente do evento</legend>

            <div class="row">
                <div
                    v-for="(item, index) in leaders"
                    :key="index"
                    class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                >
                    <label class="image-checkbox position-relative">
                        <img
                            class="img-responsive"
                            :src="`/storage/${item.identification_photo}`"
                            :class="{
                                selected: form.leaders.includes(item.id),
                            }"
                            @click="toggleLeaderSelection(item.id)"
                        />
                        <span
                            v-show="form.leaders.includes(item.id)"
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                        >
                            <font-awesome-icon :icon="['fas', 'circle-check']" />
                        </span>
                        <span class="mt-3 d-block">{{ item.name }}</span>
                        <input type="checkbox" name="image[]" value="" />
                    </label>
                </div>
            </div>

            <hr class="mb-0" />

            <legend class="mb-4">Selecione o ministro do evento</legend>

            <div class="row">
                <div
                    v-for="(item, index) in ministers"
                    :key="index"
                    class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                >
                    <label class="image-checkbox position-relative">
                        <img
                            class="img-responsive"
                            :src="`/storage/${item.identification_photo}`"
                            :class="{
                                selected: form.ministers.includes(item.id),
                            }"
                            @click="toggleMinisterSelection(item.id)"
                        />
                        <span
                            v-show="form.ministers.includes(item.id)"
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                        >
                            <font-awesome-icon :icon="['fas', 'circle-check']" />
                        </span>
                        <span class="mt-3 d-block">{{ item.name }}</span>
                        <input type="checkbox" name="image[]" value="" />
                    </label>
                </div>
            </div>

            <template v-if="form.ministers.length">
                <hr class="mb-0" />

                <legend>Dados da ministração</legend>

                <div class="col-md-12">
                    <label for="theme" class="form-label"> Tema da ministração </label>

                    <input
                        id="theme"
                        v-model="form.theme"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Obediência"
                    />
                    <small v-if="form.errors.theme" class="text-danger mt-3">
                        {{ form.errors.theme }}
                    </small>
                </div>

                <div class="col-md-12">
                    <label for="details_ministry" class="form-label">
                        Detalhes da ministração
                    </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="details_ministry"
                        v-model="form.details_ministry"
                        type="text"
                        class="form-control"
                        name="details_ministry"
                        placeholder="Forneça um breve resumo dessa programação"
                        rows="5"
                    />

                    <small v-if="form.errors.details_ministry" class="text-danger mt-3">
                        {{ form.errors.details_ministry }}
                    </small>
                </div>
            </template>

            <hr class="mb-0" />

            <div class="col-md-12">
                <label for="formFile" class="form-label"
                    >Banner de Divulgação - Dimensões recomendadas: 1080 x 1080</label
                >
                <input
                    id="formFile"
                    class="form-control"
                    type="file"
                    @input="form.banner = $event.target.files[0]"
                    @change="previewImage"
                />
                <small class="mt-3 informative-text">
                    O tamanho máximo que a imagem deve ter é de 2mb
                </small>
            </div>

            <div class="col-md-11 mx-auto">
                <img
                    v-if="imageUrl"
                    class="d-block mx-auto preview-image-banner border border-5 border-light shadow"
                    :src="imageUrl"
                />
            </div>
        </Form>
    </Dashboard>
</template>
